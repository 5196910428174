<template>
  <div class="merch">
    <el-form
      ref="queryForm"
      :model="queryParams"
      :inline="true"
      @keyup.enter.native="handleQuery"
    >
      <el-form-item prop="name" label="禁售字：">
        <el-input
          v-model="queryParams.name"
          placeholder="请输入禁售字（模糊）"
          clearable
        />
      </el-form-item>
      <el-form-item prop="type" label="禁售类型：">
        <el-select
          v-model="queryParams.type"
          placeholder="请选择禁售类型"
          clearable
        >
          <el-option
            v-for="(item, index) in typeList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="matchType" label="校验方式：">
        <el-select
          v-model="queryParams.matchType"
          placeholder="请选择校验方式"
          clearable
        >
          <el-option label="商品名和违禁字相同" :value="1" />
          <el-option label="商品名包含违禁字" :value="0" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="loading" @click="handleQuery">
          搜索
        </el-button>
        <el-button @click="Reset">重置</el-button>
      </el-form-item>
      <div style="margin-bottom: 10px">
        <el-button type="primary" plain :loading="loading" @click="Addbuyfree">
          新建
        </el-button>
      </div>
    </el-form>
    <el-table
      v-loading="loading"
      :header-cell-style="{ background: '#F2F3F5' }"
      :data="dataList"
      border
      style="width: 100%"
    >
      <el-table-column prop="id" label="禁售字编号" align="center" />
      <el-table-column prop="name" label="禁售字" align="center" />
      <el-table-column prop="type" label="禁售类型" align="center">
        <template slot-scope="{ row }">
          <div v-for="(item, index) in typeList" :key="index">
            <div v-if="item.id == row.type">{{ item.name }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="matchType" label="校验方式" align="center">
        <template slot-scope="{ row }">
          {{
            row.matchType == 1 ? "商品名与禁售字完全相同" : "商品名包含禁售字"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="status" align="center" label="状态">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.status"
            active-color="#13ce66"
            inactive-color="#cccccc"
            :active-value="1"
            :inactive-value="0"
            @change="changeStatus(row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="operatorName" label="创建人" align="center" />
      <el-table-column prop="createTime" label="创建时间" align="center" />
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <span class="btsed" @click="Deletitem(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.perPage"
      @pagination="getList"
    />
    <!-- 添加弹窗 -->
    <el-dialog
      v-if="dialogAdd"
      title="新建禁售字"
      :close-on-click-modal="false"
      :visible.sync="dialogAdd"
      width="550px"
    >
      <el-form
        :model="addItem"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item label="违 禁 字：" prop="name">
          <el-input
            v-model="addItem.name"
            placeholder="请输入违禁字（不可重复创建）"
            clearable
            style="width: 350px"
          ></el-input>
        </el-form-item>
        <el-form-item label="禁售类型：" prop="type">
          <el-select
            style="width: 350px"
            v-model="addItem.type"
            placeholder="请选择禁售类型"
            clearable
          >
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="校验方式：" prop="matchType">
          <el-radio-group v-model="addItem.matchType">
            <el-radio :label="1">商品名与禁售字完全相同</el-radio>
            <el-radio :label="0">商品名包含禁售字</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div style="margin: 20px 10px" class="red">
        注意：仅在新商品创建时校验，已创建商品未匹配。
      </div>
      <span slot="footer">
        <el-button @click="dialogAdd = false">取 消</el-button>
        <el-button type="primary" @click="handleAdd" :loading="loading"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  prohibitedlist, // 禁售字库-列表
  prohibitedadd, // 禁售字库-创建
  prohiStatus, // 禁售字库-状态切换
  prohidelete, // 禁售字库-删除
  typeList, // 禁售字禁售类型下拉列表
} from "@/api/apiAll/phpUrl.js";
export default {
  name: "buyforfree",
  data() {
    return {
      loading: false,
      total: 0,
      // 搜索栏
      queryParams: {
        page: 1,
        perPage: 10,
        name: "", // 禁售字
        type: "", //禁售类型1.常规禁售,2.麻醉药品,3.精神药品,4.非药用麻醉和精神管控,5.放射性药品,6.制毒化学品
        matchType: "", //校验方式0商品名包含违禁字，1商品名与违禁字相同
      },
      dataList: [], //列表数据

      typeList: [], //禁售类型下拉列表
      dialogAdd: false, //添加弹窗
      addItem: {
        name: "", //违禁字
        type: "", //禁售类型
        matchType: "", //校验方式
      },
      rules: {
        name: [{ required: true, message: "请输入违禁字", trigger: "blur" }],
        type: [
          { required: true, message: "请选择禁售类型", trigger: "change" },
        ],
        matchType: [
          { required: true, message: "请选择校验方式", trigger: "change" },
        ],
      },
    };
  },
  created() {
    //获取禁售类型下拉列表
    typeList().then((res) => {
      this.typeList = res.data;
    });
    //获取存储查询
    let path = this.$route.path;
    if (this.$store.getters.routData[path]) {
      Object.assign(
        this.queryParams,
        this.$store.getters.routData[path].queryParams
      );
    }
    this.getList();
  },
  filters: {},
  methods: {
    //存储查询状态
    setquery() {
      var path = this.$route.path;
      var data = {};
      data[path] = {
        queryParams: this.queryParams,
      };
      this.$store.dispatch("routing/querying", data);
    },
    /** 查询列表数据 */
    async getList() {
      this.loading = true;
      let res = await prohibitedlist(this.queryParams);
      this.loading = false;
      this.dataList = res.data.data;
      this.total = res.data.total;
      this.setquery();
    },
    /** 搜索按键操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置 */
    Reset() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getList();
    },
    /** 状态切换 */
    changeStatus(row) {
      prohiStatus({ id: row.id }).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
        }
        this.getList();
      });
    },
    /** 删除 */
    Deletitem(row) {
      this.$confirm(
        `确认删除禁售字？删除后将无法限制管控此禁售字。`,
        "删除禁售字",
        {
          closeOnClickModal: false,
          cancelButtonText: "取消",
          confirmButtonText: "删除",
          type: "warning",
        }
      )
        .then(() => {
          prohidelete({ id: row.id }).then((res) => {
            if (res.code == "200") {
              this.$message.success("删除成功");
              if (this.queryParams.page > 1 && this.dataList.length == 1) {
                this.queryParams.page--;
              }
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    /** 新建 */
    Addbuyfree() {
      Object.assign(this.addItem, this.$options.data().addItem);
      this.dialogAdd = true;
    },
    /** 新建保存 */
    handleAdd() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let res = await prohibitedadd(this.addItem);
          this.loading = false;
          if (res.code == 200) {
            this.$message.success("操作成功");
            this.dialogAdd = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
